import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Logo from '../../elements/Logo';
import NavLink from '../NavLink';
import NavSocials from '../NavSocials';

const ns = 'navigation';

class Navigation extends Component {
  render() {
    return (
      <div className={`${ns}`}>
        <Link to='/'>
          <Logo/>
        </Link>

        <div className={`${ns}__links`}>
          <NavLink to='/about'>About</NavLink>
          <NavLink to='/work'>Projects</NavLink>
          <NavLink to='/lab'>Lab</NavLink>
          <NavSocials
            socialIcons={[
              {
                icon: '/assets/home/github-icon-2x.png',
                alt: 'Github Icon Link',
                link: 'https://github.com/deegiialt'
              },
              {
                icon: '/assets/home/codepen-icon-2x.png',
                alt: 'Codepen Icon Link',
                link: 'https://codepen.io/deegiialt/'
              },
              {
                icon: '/assets/home/linkedin-icon-2x.png',
                alt: 'LinkedIn Icon Link',
                link: 'https://www.linkedin.com/in/deegii-altangerel/'
              },
              {
                icon: '/assets/home/mail-icon-2x.png',
                alt: 'Mail Icon Link',
                link: 'mailto:aagiideegii@gmail.com'
              }
            ]}
          />
        </div>
      </div>
    );
  };
};

export default Navigation;