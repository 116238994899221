import { createGlobalStyle } from 'styled-components'
// import { rotate } from '../animations'

export const themes = {
  theme1: {
    name: 'neutral',
    colorNudePrimary: '#DAA89B',
    colorRosePrimary: '#AE847E',
    colorPurplePrimary: '#461220',
    colorPinkPrimary: '#8C2F39',
    colorChestnutPrimary: '#B23A48',
    colorNude2: '#E0B7AD',
    colorNude3: '#DDAFA4',
    colorWhite: '#FFF'
  },
  theme2: {
    name: 'retrowave'
  }
}

export const GlobalStyle = createGlobalStyle`
  // Reset
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
`
