import React, { Component } from 'react'

class Logo extends Component {
  state = {
    rotate : 0
  }

  componentDidMount() {
    window.addEventListener('scroll', () => this.onScroll());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.onScroll());
  }

  onScroll() {
    let rotate = (window.scrollY * 0.7)
    this.setState({ rotate })
  }

  render() {
    const ns = 'logo'
    const styles = {
      transform: `rotate(${this.state.rotate}deg)`
    }

    return(
      <div className={ns}>
          <img className={`${ns}__image`} src="/assets/home/logox-dark-semibold.png" alt="Spinning logo that says deegi alt" style={styles} />           
      </div>
    )
  }
}

export default Logo