import React, { Component } from 'react'
import * as THREE from 'three'
import WEBGL from '../../vendor/webgl'
import styled from 'styled-components'

import DefaultLayout from '../DefaultLayout';
var OrbitControls = require('three-orbit-controls')(THREE)

var camera, renderer, scene, polygon, particle;

const Three = styled.div`
  // background-image: linear-gradient(to top,  #11e8bb 0%, #8200c9 100%);
  background-image: linear-gradient(to top,#97DFFC 0%, #E5C3D1 100%);
  overflow: hidden;
`

class PolygonWorld extends Component {
  
  componentDidMount() {
    this.initScene()

    // Update scene width/height on window resize
    window.addEventListener('resize', this.onWindowResize, false);
    // document.addEventListener('mousemove', this.onMouseMove, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
    // document.removeEventListener('mousemove', this.onMouseMove);
  }

  initScene() {
    // Init Renderer
    renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor( 0xffffff, 0);
    renderer.setPixelRatio((window.devicePixelRatio) ? window.devicePixelRatio : 1);
    document.getElementById('polygonWorld').appendChild(renderer.domElement);

    // Init Scene
    scene = new THREE.Scene();

    // Init Camera
    camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );

    // Allows click + drag/ zoom
    new OrbitControls( camera );

    // Drawing shapes
    var geometry = new THREE.IcosahedronGeometry(4, 1);
    var particleGeometry = new THREE.OctahedronBufferGeometry(10, 0);
    var material = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      wireframe: true,
    });
    var particleMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
    });
    polygon = new THREE.Mesh( geometry, material );
    // polygon.position.set( 0, -4 , 0 )
    polygon.geometry.scale(4,4,4)
    scene.add( polygon );

    particle = new THREE.Object3D();
    particle.scale.z = particle.scale.x = particle.scale.y = 1;
    scene.add(particle)

    for (var i = 0; i < 4000; i++) {
      var mesh = new THREE.Mesh(particleGeometry, particleMaterial);
      mesh.position.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize();
      mesh.position.multiplyScalar(75 + (Math.random() * 900));
      mesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
      mesh.scale.x = mesh.scale.y = mesh.scale.z = 0.1;
      particle.add(mesh);
    }

    // Init Lighting
    var ambientLight = new THREE.AmbientLight(0x999999);
    scene.add(ambientLight);

    var lights = [];
    lights[0] = new THREE.DirectionalLight( 0xffffff, 1 );
    lights[0].position.set( 1, 0, 0 );
    lights[1] = new THREE.DirectionalLight( 0x11E8BB, 1 );
    lights[1].position.set( 0.75, 1, 0.5 );
    lights[2] = new THREE.DirectionalLight( 0x8200C9, 1 );
    lights[2].position.set( -0.75, -1, 0.5 );
    scene.add( lights[0] );
    scene.add( lights[1] );
    scene.add( lights[2] );

    camera.position.z = 50;

    var animate = function () {
      requestAnimationFrame( animate );
      
      polygon.rotation.x -= 0.0020;
      polygon.rotation.y -= 0.0030;

      particle.rotation.x += 0.0000;
      particle.rotation.y -= 0.0040;

      renderer.render( scene, camera );
    };

    // If Webgl available, initialize animation
    if ( WEBGL.isWebGLAvailable() ) {
      animate()
    } else {
      var warning = WEBGL.getWebGLErrorMessage();
      document.getElementById( 'polygonWorld' ).appendChild( warning );
    }
  }

  onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.updateProjectionMatrix();
  }

  onMouseMove = (event) => {
    let mouseX = event.clientX - window.innerWidth / 2;
    let mouseY = event.clientY - window.innerHeight / 2;

    camera.position.x += (mouseX - camera.position.x) * 0.005;
    camera.position.y += (mouseY - camera.position.y) * 0.005;
    // set up camera position
    camera.lookAt(scene.position);
  }
  
  render() {
    return (
      <DefaultLayout>
        <Three id='polygonWorld'/>
      </DefaultLayout>
    )
  }
}

export default PolygonWorld