import React from 'react';

const ns = 'navSocials'

const NavSocials = (props) => {
  const {
    socialIcons
  } = props;

  return (
    <div className={`${ns}`}>
      {
        socialIcons.map((socialIcon, i) => {
          return (
            <a href={socialIcon.link} key={i}>
              <img src={socialIcon.icon} alt={socialIcon.alt} className={`${ns}__social-icon`}/>
            </a>
          )
        })
      }
    </div>
  );
};

export default NavSocials;