import React, { Component } from 'react'
import styled from 'styled-components'
import anime from 'animejs'

const BgAnimation = styled.div`
  position: relative;
  width: 800px;
  margin-bottom: 30%;
  margin-top: 10%;

  @media screen and (max-width: 1140px) {
    margin-bottom: 50%;
  }

  & svg {
    max-width: 100%;

    path {
      fill: ${props => props.bgColor};
    }
  }
`

class BgAnimationWrapper extends Component {
  state = {
    scrollPosition: null,
    paths: this.props.path
  }

  componentDidMount() {
    // window.addEventListener('scroll', ()=>this.listenScrollEvent());

    this.animeRef = anime({
      targets: '.p',
      d: [
        {value: this.state.paths.primary},
        // {value: this.state.paths.secondary},
        // {value: this.state.paths.primary}
        // {value: 'M1131 244.5C577.552 -359.38 69.0002 349 69.0002 349C69.0002 349 -84.6514 627.253 69.0001 827C189 983 459.399 1028.41 704.999 1003C1140 958 1345 478 1131 244.5Z'}
      ],
      easing: 'easeInOutQuad',
      direction: "alternate",
      duration: 1000,
      loop: true,
      // translateY: `${this.state.scrollPosition}px`
    })
  }

  listenScrollEvent() {
    let scrollPosition = window.pageYOffset;
    this.setState({ scrollPosition })
  }

  render() {  
    return (
      <div className="BgAnimationWrapper">
        <BgAnimation className="poly-animation" bgColor={this.props.bgColor}>
          <svg width="800" height="400" viewBox="0 0 1400 700" fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path className='p' d='M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z'
            fill='#000' />
          </svg>
          {/* <svg width="800" height="400" viewBox="0 0 1400 700" fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path className='p' d='M852.5 189.5C200.138 -305.878 11.5 331.5 11.5 331.5C11.5 331.5 -31.5 508 53.0003 675C154.957 876.5 443.4 876.406 689 850.999C1124 806 1039.5 331.5 852.5 189.5Z'
            fill='#000' />
          </svg> */}
          {this.props.children}
        </BgAnimation>
      </div>
    )
  }
}

export default BgAnimationWrapper