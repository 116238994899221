import React, { Component } from 'react';
// import TweenMax, { Linear } from 'gsap/TweenMax'
import anime from 'animejs';
import * as THREE from 'three'
import WEBGL from '../../vendor/webgl'
import CoordinateTranslator from '../../vendor/coordinateTranslator'
// import { isMobile } from 'react-device-detect';
import threeHelpers from '../../functions/threeHelpers';

import DefaultLayout from '../DefaultLayout';

// const OrbitControls = require('three-orbit-controls')(THREE)
// const OBJLoader = require('../../vendor/objLoader')(THREE)

const ns = 'home';
let scene, camera, renderer;

class Home extends Component {
  constructor(props) {
    super(props);

    // Containers
    this.container = null;
    this.divContainer = null;
    this.scrollPercentage = null;

    // Three orbit
    this.pivotPoint = null;
    this.diamondMesh = null;
    // this.textParent = null;
    this.objects = [];
    // this.texts = [];

    // Scroll/wheel
    this._event = {
      y: 0,
      deltaY: 0
    };
    this.touchStartY = 0;
    this.scrollY = 0;
    this.timeline = null;
    this.percentage = 0;
    this.maxHeight = 0;

    this.onWheel = this.onWheel.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount () {
    this.maxHeight = (this.divContainer.clientHeight || this.divContainer.offsetHeight) - window.innerHeight;
    // Update scene width/height on window resize
    window.addEventListener('resize', this.onWindowResize, { passive: true
    });
    this.divContainer.addEventListener('wheel', this.onWheel, { passive: false });
    // this.divContainer.addEventListener('wheel', (e) => threeHelpers.onWheel(e, this._event, this.maxHeight, this.scrollY), { passive: false });
    // Mobile
    this.divContainer.addEventListener('touchstart', this.onTouchStart, { passive: false });
    this.divContainer.addEventListener('touchmove', this.onTouchMove, { passive: false });

    // Initialize three.js scene
    this.init();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchmove', this.onTouchMove);
  }

  init() {
    this.initRenderer();
    this.initScene();
    threeHelpers.initLighting(scene);
    // threeHelpers.writeText('TEXT', 30, this.textParent, scene);
    this.initObjects();
    this.initTimeline();
    this.initAnimation();
  }

  onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onWheel (e) {
    const evt = this._event;
    evt.deltaY = e.wheelDeltaY || e.deltaY * -1;
    // reduce by half the delta amount otherwise it scroll too fast
    evt.deltaY *= 0.5;

    this.scroll(e);
  };
  
  scroll (e) {
    const evt = this._event;
    // limit scroll top
    if ((evt.y + evt.deltaY) > 0 ) {
      evt.y = 0;
    // limit scroll bottom
    } else if ((-(evt.y + evt.deltaY)) >= this.maxHeight) {
      evt.y = -this.maxHeight;
    } else {
      evt.y += evt.deltaY;
    }
    this.scrollY = -evt.y
  }
  
  // Mobile
  onTouchStart (e) {
    const t = (e.targetTouches) ? e.targetTouches[0] : e;
    this.touchStartY = t.pageY;
  };
  
  onTouchMove (e) {
    const evt = this._event;
    const t = (e.targetTouches) ? e.targetTouches[0] : e;
    evt.deltaY = (t.pageY - this.touchStartY) * 5;
    this.touchStartY = t.pageY;
  
    this.scroll(e)
  };

  initRenderer() {
    //---- Renderer ----//
    renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor( 0xffffff, 0);
    // renderer.setClearColor(0x161216);
    renderer.setPixelRatio((window.devicePixelRatio) ? window.devicePixelRatio : 1);
    this.container.appendChild(renderer.domElement);
  }

  // Linear interpolation function for smoothing + easing.
  lerp(a, b, t) {
    return ((1 - t) * a + t * b);
  }

  initScene() {
    //---- Scene ----//
    scene = new THREE.Scene();

    //---- Camera ----//
    camera = new THREE.PerspectiveCamera( 35, window.innerWidth/window.innerHeight, 1, 10000 ); //FOV, Aspect Ratio, Near, Far

    camera.position.x = 300;
    camera.position.y = 400;
    camera.position.z = 2000;

    // if(isMobile) {
    //   camera.position.z = 5000;
    // }
    camera.lookAt(scene.position);
  }

  renderScene() {
    renderer.render( scene, camera );
  };

  initAnimation() {
    let animate = () => {
      // easing with treshold on 0.08 (should be between .14 & .2 for smooth animations)
      this.percentage = this.lerp(this.percentage, this.scrollY, .08);
      this.timeline.seek(this.percentage * (4500 / this.maxHeight));
      // if(this.scrollPercentage) {
      //   this.scrollPercentage.innerHTML = 'Anim progress : ' + Math.round(this.timeline.progress * 100) / 100 + '%';
      // }
      
      requestAnimationFrame( animate );

      // Animating sphere 2
      this.pivotPoint.rotation.y += 0.01;
      // for(var i = 0; i < 4; i++) {
      //   this.pivotPoint.rotation.x = i * 0.95
      // }

      this.renderScene();  
    };

    // If Webgl available, initialize animation
    if ( WEBGL.isWebGLAvailable() ) {
      animate();
    } else {
      const warning = WEBGL.getWebGLErrorMessage();
      this.container.appendChild( warning );
    }
  }

  initObjects() {
    const geometry = new THREE.OctahedronBufferGeometry(10, 2);
    const material = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      wireframe: true
    });

    for (let i = 0; i < 4; i++) {
      let mesh = new THREE.Mesh(geometry, material);
      mesh.position.multiplyScalar(10 + Math.random() * 90);
      mesh.scale.x = mesh.scale.y = mesh.scale.z = 8;
      this.objects.push(mesh)
    }

    // Sphere Geometry 1
    const sunGeom = new THREE.OctahedronBufferGeometry(10);
    // Sphere Material 1
    const sunMat = new THREE.MeshLambertMaterial({
      color: 0xfccdd3,
      // wireframe: true
    });
    // Sphere Mesh 1
    this.diamondMesh = new THREE.Mesh(sunGeom, sunMat);
    this.diamondMesh.position.set(0, -100, 0);
    scene.add(this.diamondMesh);

    // console.log(this.textParent.children);
    // console.log(this.textParent.children[0]);
    // Makes pivot point
    this.pivotPoint = new THREE.Object3D();
    this.diamondMesh.add(this.pivotPoint);

    for(let _i = 0; _i < 4; _i++){
      let _coords = (new CoordinateTranslator()).fromSpherical(540, 0, 90*_i)
      this.objects[_i].position.set(_coords.x, _coords.y, _coords.z);
      this.pivotPoint.add(this.objects[_i]);
    }
  };

  initTimeline() {
    this.timeline = anime.timeline({
      autoplay: false,
      duration: 4500,
      easing: 'easeOutSine'
    });
    this.timeline.add({
      targets: this.diamondMesh.rotation,
      x: 0,
      y: 25,
      z: 0,
      duration: 4500,
      update: camera.updateProjectionMatrix()
    });
    // this.timeline.add({
    //   targets: this.diamondMesh.rotation,
    //   x: 0,
    //   y: -25,
    //   z: 0,
    //   duration: 2250,
    //   update: camera.updateProjectionMatrix()
    // });
  }

  render() {
    return (
      <DefaultLayout>       
        <div ref={div => this.container = div} id={`${ns}__webgl`} className={`${ns}__webgl`}/>
        <div ref={div => this.divContainer = div} className={`${ns}__container`}>
          {/* <div ref={div => this.scrollPercentage = div} className='home__scrollPercentage'></div> */}
          <div className={`${ns}__heading-container`}>
            <h1 className={`${ns}__heading`}>Hello, I'm <span className={`${ns}__heading--name`}>Deegii</span></h1>
            <h2 className={`${ns}__heading--sub`}>a software engineer</h2>
          </div>
        </div>
      </DefaultLayout>
    )
  }
};

export default Home;