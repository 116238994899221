import React, { Component } from 'react'
import * as THREE from 'three'
import WEBGL from '../../vendor/webgl'
import styled from 'styled-components'
import projectData from '../../../projectsV3.json'
import {
  isMobile
} from "react-device-detect";
import TweenMax, { Linear } from 'gsap/TweenMax'
// var OrbitControls = require('three-orbit-controls')(THREE)
import DefaultLayout from '../DefaultLayout';
var OrbitControls = require('../../vendor/orbitControls')(THREE)
// var DeviceOrientationControls = require('../../vendor/deviceOrientationControls')(THREE)

var camera, renderer, scene, raycaster, mouse, controls, videos;
if(isMobile) {
  // var controlsDevice;
}

const ns = 'projects-v3';

const Three = styled.div`
  overflow: hidden;
  overscroll-behavior: none;
`

class ProjectsV3 extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      mouseX: 0,
      mouseY: 0,
      activeVideo: null,
      nonActiveVideos: [],
      scroll: false
    }

    this.threeContainer = null;
    this.onMouseMoveCamera = this.onMouseMoveCamera.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  
  componentDidMount() {
    this.initScene();
    this.writeText();
    this.createVideos();
    this.initLighting();
    this.initAnimation();

    // Update scene width/height on window resize
    window.addEventListener('resize', this.onWindowResize, false);
    // Change cursor when projects are hovered
    window.addEventListener('mousemove', (event) => { 
      this.mouseEvent(event, this.onMouseMoveHover) 
      this.onMouseMoveCamera(event);
    }, false);
    // On click of project, take to url
    window.addEventListener('mousedown', (event) => { this.mouseEvent(event, this.onMouseDown) }, false);
    // document.addEventListener('mousemove', this.onMouseMoveCamera, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('mousemove', this.onMouseMoveHover, false);
    window.removeEventListener('mousedown', this.onMouseDown, false);
    // document.removeEventListener('mousemove', this.onMouseMoveCamera);
  }

  handleScroll() {
    this.setState({ scrolled: true });
  };

  initScene() {
    //---- Renderer ----//
    renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor( 0xffffff, 0);
    renderer.setPixelRatio((window.devicePixelRatio) ? window.devicePixelRatio : 1);
    this.threeContainer.appendChild(renderer.domElement);

    //---- Scene ----//
    scene = new THREE.Scene();

    //---- Camera ----//
    camera = new THREE.PerspectiveCamera( 20, window.innerWidth/window.innerHeight, 0.1, 1000 );

    //---- Controls ----//
    // Allows click + drag/ zoom
    controls = new OrbitControls( camera, renderer.domElement );
    controls.enableRotate = false;
    controls.maxDistance = 1500;
    controls.minDistance = 100;
    controls.zoomSpeed = 0.5;
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.dampingFactor = 0.70;

    if(isMobile) {
      controls.zoomSpeed = 2;
    }
    
    //---- Gyroscope ----//
    // Gyroscope functionality for mobile
    // if(isMobile) {
    //   controlsDevice = new DeviceOrientationControls( camera )
    // }
  }

  initCameraMove() {
    const box = new THREE.Box3();
    box.setFromObject(scene);
    if(!isMobile) {
      if(this.state.activeVideo === null) {
        if(camera.position.x > box.max.x){
          camera.position.x = box.max.x - 200;
        }
        
        if(camera.position.x < box.min.x){
          camera.position.x = box.min.x;
        }
        
        if(camera.position.y > box.max.y){
          camera.position.y = box.max.y;
        }
        
        if(camera.position.y < box.min.y){
          camera.position.y = box.min.y;
        }
        camera.position.x += ( this.state.mouseX - camera.position.x ) * .00025;
        camera.position.y += ( - ( this.state.mouseY - 50 ) - camera.position.y ) * .00025;
      }
    }
  }

  writeText() {
    //---- Writing Text ----//
    var text = 'WORK';
    var loader = new THREE.FontLoader();

    loader.load( 'assets/fonts/PlayfairDisplay_Regular.json', function ( font ) {
      var textGeom = new THREE.TextGeometry( text, {
        font: font,
        size: 30,
        height: 0
      });

      textGeom.center();

      textGeom.computeBoundingBox();
      textGeom.center();

      var textMaterial = new THREE.MeshPhongMaterial( {
        color: 0xD00000,
        blending: THREE.AdditiveBlending
      });
      var textMesh = new THREE.Mesh( textGeom, textMaterial );
      textMesh.position.set(0,0,600);
      textMesh.castShadow = true;
      textMesh.receiveShadow = true;
      scene.add( textMesh );
    });
  }

  createVideos() {
    //---- Drawing shapes ----//
    videos = []

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    //---- Videos ----//
    // Creating
    projectData.forEach((project, i) => {
      // var videoId = `video-${project.id}`
      var video = document.getElementById(`project-video-${project.id}`);
      // console.log(video)
      video.play()
      var vidTexture = new THREE.VideoTexture(video);
      // var vidGeometry = new THREE.BoxBufferGeometry(1,1,1)
      // var vidMaterial = new THREE.MeshPhongMaterial( { map: vidTexture} );
      var vidMaterial = new THREE.SpriteMaterial({ 
        color: 0xffffff, 
        map: vidTexture
      });
      videos.push(new THREE.Sprite( vidMaterial ))
      videos[i].name = i;
    })

    // Positioning/scaling/adding videos individually
    // console.log(videos)
    videos[0].position.set(15, 3, 350);
    videos[0].scale.set(30, 16, 0.1);
    scene.add(videos[0]);
    videos[1].position.set(-35,-3,250);
    videos[1].scale.set(30, 16, 0.1);
    scene.add(videos[1]);
    videos[2].position.set( 20, 0, 150);
    videos[2].scale.set(30, 16, 0.1);
    scene.add(videos[2]);
    videos[3].position.set( -35, -3, 50);
    videos[3].scale.set(30, 16, 0.1);
    scene.add(videos[3]);
    videos[4].position.set( 20, 0, 30);
    videos[4].scale.set(30, 16, 0.1);
    scene.add(videos[4]);
    videos[5].position.set( -10, 5, -10);
    videos[5].scale.set(30, 16, 0.1);
    scene.add(videos[5]);
  }

  initLighting() {
    // ---- Lighting ----//
    // Text
    var pointLightRed = new THREE.PointLight(0xD00000, 1.7);
    pointLightRed.position.set(0, 200, 800);
    scene.add( pointLightRed );
    // var ambientLight = new THREE.AmbientLight(0x999999);
    // scene.add(ambientLight);
    var lights = new THREE.DirectionalLight( 0x0E4749, 1 );
    lights.position.set( 0, 0, 700 );

    camera.position.z = 1500;
  }

  initAnimation() {
    var animate = () => {
      requestAnimationFrame( animate );

      // this.initCameraMove();

      // if(isMobile) {
      // }
      controls.update();

      render();   
    };

    var render = () => {
      renderer.render( scene, camera );
    };

    // If Webgl available, initialize animation
    if ( WEBGL.isWebGLAvailable() ) {
      animate();
    } else {
      var warning = WEBGL.getWebGLErrorMessage();
      this.threeContainer.appendChild( warning );
    }
  }

  onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onMouseMoveCamera(event) {
    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;

    let mouseX = ( event.clientX - windowHalfX );
    let mouseY = ( event.clientY - windowHalfY );

    this.setState({ mouseX, mouseY });
  }

  onMouseMoveHover = (intersects) => {  
    var html = document.querySelector('html, body');

    if(intersects.length > 0) {
      if(intersects[0].object.name.length !== 0) {
        html.style.cursor = 'pointer';
      }
    } else {
      html.style.cursor = 'default';
    }
  }

  onMouseDown = (intersects) => {
    // console.log(intersects[0]);
    if(intersects.length > 0) {
      controls.saveState();
      controls.enableZoom = false;

      for(var i = 0; i < videos.length; i++) {
        if(intersects[0].object.name === videos[i].name) {
          // var objPos = videos[i].position
          // camera.lookAt(objPos)

          var bb = new THREE.Box3()
          bb.setFromObject(videos[i]);
          const centerPosition = bb.getCenter();

          // TweenMax.to(camera.position, 1, {
          //   ease: Linear.easeInOut,
          //   // x: videos[i].position.x,
          //   // y: (videos[i].position.y - 6),
          //   // z: (videos[i].position.z + 100)
          //   x: centerPosition.x,
          //   y: centerPosition.y,
          //   z: (videos[i].position.z + 100)
          // })

          const cameraZ = isMobile ? (videos[i].position.z + 140) : (videos[i].position.z + 100);
          TweenMax.to(camera.position, 1, {
            ease: Linear.easeInOut,
            // x: videos[i].position.x,
            // y: (videos[i].position.y - 6),
            // z: (videos[i].position.z + 100)
            // x: centerPosition.x,
            // y: centerPosition.y,
            z: cameraZ
          })
          TweenMax.to(controls.target, 1, {
            ease: Linear.easeInOut,
            x: centerPosition.x,
            y: centerPosition.y - 5,
            z: centerPosition.z
          })

          const nonActiveVideos = videos.filter(video => video.name !== intersects[0].object.name);
          this.setState({ activeVideo: i + 1, nonActiveVideos });

          nonActiveVideos.forEach((video) => {
            video.visible = false;
          })

          // window.open(`/work${projectData[videos[i].name].link}`)
        }
      }
    } else {
      this.state.nonActiveVideos.forEach((video) => {
        video.visible = true;
      })
      this.setState({ activeVideo: null });
      controls.reset();
      controls.enableZoom = true;
    }
  }

  mouseEvent (event, callback) {
    if (event.target === renderer.domElement) {
      mouse.x = ( event.clientX / renderer.domElement.clientWidth ) * 2 - 1;
      mouse.y = - ( event.clientY / renderer.domElement.clientHeight ) * 2 + 1;
      var intersects = raycaster.intersectObjects(scene.children);
      raycaster.setFromCamera( mouse, camera );
    
      callback(intersects)
    }
  }
  
  render() {
    const { activeVideo, scrolled } = this.state;
    const videoClass = activeVideo === null ? '' : `video-active video-active--${activeVideo}`;
    const scrollClass = scrolled ? 'scrolled' : '';

    return (
      <DefaultLayout>
        <Three ref={div => this.threeContainer = div}>
          <div className={`${ns}__scroll ${scrollClass}`}>
            <span className={`${ns}__scroll-icon`}>
              <span className={`${ns}__scroll-icon--dot`}></span>
            </span>
          </div>
          {
            projectData.map((project) => (
              <div className={`${ns}__project-container ${videoClass}`} key={project.id}>
                <video id={`project-video-${project.id}`} key={project.id} loop={true} preload="true" autoPlay playsInline webkit-playsinline="true" muted width="500" style={{display: "none"}}>
                  <source src={project.vid} type="video/mp4" />
                </video> 
                <div className={`${ns}__info ${ns}__info--${project.id}`}>
                  <h3 className={`${ns}__title`}>{project.title}</h3>
                  <p className={`${ns}__description`}>{project.description}</p>
                  <span className={`${ns}__disclaimer`}>{project.disclaimer}</span>
                  <br/>
                  <a className={`${ns}__visit`} href={project.link} target="_blank" rel="noopener noreferrer">visit site</a>
                </div>
              </div>
            ))
          }
        </Three>
      </DefaultLayout>
    )
  }
}

export default ProjectsV3