import * as THREE from 'three';

const onWheel = (e, _event, maxHeight, scrollY) => {
  const evt = _event;
  evt.deltaY = e.wheelDeltaY || e.deltaY * -1;
  // reduce by half the delta amount otherwise it scroll too fast
  evt.deltaY *= 0.5;

  const scroll = (e) => {
    const evt = _event;
    // limit scroll top
    if ((evt.y + evt.deltaY) > 0 ) {
      evt.y = 0;
    // limit scroll bottom
    } else if ((-(evt.y + evt.deltaY)) >= maxHeight) {
      evt.y = -maxHeight;
    } else {
      evt.y += evt.deltaY;
    }
    scrollY = -evt.y;
  }

  scroll(e);
};

// // Mobile
// const onTouchStart = (e) => {
//   const t = (e.targetTouches) ? e.targetTouches[0] : e;
//   touchStartY = t.pageY;
// };

// const onTouchMove = (e) => {
//   const evt = _event;
//   const t = (e.targetTouches) ? e.targetTouches[0] : e;
//   evt.deltaY = (t.pageY - touchStartY) * 5;
//   touchStartY = t.pageY;

//   scroll(e)
// };

const initRenderer = (renderer, container) => {
  //---- Renderer ----//
  renderer = new THREE.WebGLRenderer({ 
    antialias: true,
    alpha: true 
  });
  renderer.setSize( window.innerWidth, window.innerHeight );
  renderer.setClearColor( 0xffffff, 0);
  // renderer.setClearColor(0x161216);
  renderer.setPixelRatio((window.devicePixelRatio) ? window.devicePixelRatio : 1);
  container.appendChild(renderer.domElement);
}

const initLighting = (scene) => {
  // Init Lighting
  const ambientLight = new THREE.AmbientLight(0x999999);
  scene.add(ambientLight);

  let lights = [];
  // lights[0] = new THREE.DirectionalLight( 0xffffff, 1 );
  // lights[0].position.set( 1, 0, 0 );
  lights[1] = new THREE.DirectionalLight( 0x11E8BB, 1 );
  lights[1].position.set( 0.75, 1, 0.5 );
  lights[2] = new THREE.DirectionalLight( 0x8200C9, 1 );
  lights[2].position.set( -0.75, -1, 0.5 );
  // scene.add( lights[0] );
  scene.add( lights[1] );
  scene.add( lights[2] );
}

const writeText = (text, size, textParent, scene) => {
  //---- Writing Text ----//
  const loader = new THREE.FontLoader();

  textParent = new THREE.Group();
  scene.add( textParent );

  loader.load( 'assets/fonts/PlayfairDisplay_Regular.json', function ( font ) {
    const textGeom = new THREE.TextGeometry( text, {
      font,
      size,
      height: 0
    });

    // Centering text in container
    // textGeom.center();

    // textGeom.computeBoundingBox();
    textGeom.center();

    const textMaterial = new THREE.MeshPhongMaterial( {
      color: 0xB23A48,
      blending: THREE.AdditiveBlending
    });
    const textMesh = new THREE.Mesh( textGeom, textMaterial );
    // textMesh.position.set(0,0,1000);
    // textMesh.castShadow = true;
    // textMesh.receiveShadow = true;
    textParent.add( textMesh );
  });
};

export default {
  onWheel,
  // onTouchStart,
  // onTouchMove,
  initRenderer,
  initLighting,
  writeText
};