import React from 'react';
import { Link } from 'react-router-dom';

const ns = 'navLink';

const NavLink = (props) => {
  const {
    to,
    children,
  } = props;

  return (
    <div className={`${ns} ${ns}--${children.toLowerCase()}`}>
      <Link to={to} className={`${ns}__link`}>
        <h2 className={`${ns}__title`}>{children}</h2>
      </Link>
    </div>
  );
};

export default NavLink;