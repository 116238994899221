import React, { Component } from 'react';
import TweenMax, { Linear } from 'gsap/TweenMax'
import * as THREE from 'three'
import WEBGL from '../../vendor/webgl'
import CoordinateTranslator from '../../vendor/coordinateTranslator'

import DefaultLayout from '../DefaultLayout';

const OrbitControls = require('three-orbit-controls')(THREE)
const OBJLoader = require('../../vendor/objLoader')(THREE)

let camera, renderer, scene, pivotPoint, diamondMesh;
// , raycaster, mouse,;
let objects = [];

class Lab extends Component {

  componentDidMount () {
    // Update scene width/height on window resize
    window.addEventListener('resize', this.onWindowResize, false);
    // window.addEventListener('wheel', (event) => { this.onScroll(event) }, false);

    // Initialize three.js scene
    this.init();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onWindowResize);
  }

  init() {
    this.initRenderer();
    this.initScene();
    this.initLighting();
    this.initObjects();
    this.initAnimation();
  }

  onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  initRenderer() {
    //---- Renderer ----//
    renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setSize( window.innerWidth, window.innerHeight );
    // renderer.setClearColor( 0xffffff, 0);
    renderer.setPixelRatio((window.devicePixelRatio) ? window.devicePixelRatio : 1);
    document.getElementById('lab').appendChild(renderer.domElement);
  }

  initScene() {
    //---- Scene ----//
    scene = new THREE.Scene();

    //---- Camera ----//
    camera = new THREE.PerspectiveCamera( 35, window.innerWidth/window.innerHeight, 1, 10000 ); //FOV, Aspect Ratio, Near, Far

    camera.position.x = 300;
    camera.position.y = 400;
    camera.position.z = 2000;
    camera.lookAt(scene.position);

    //---- Controls ----//
    // Allows click + drag/ zoom
    new OrbitControls( camera, renderer.domElement );
  }

  initLighting() {
    // Init Lighting
    const ambientLight = new THREE.AmbientLight(0x999999);
    scene.add(ambientLight);

    let lights = [];
    // lights[0] = new THREE.DirectionalLight( 0xffffff, 1 );
    // lights[0].position.set( 1, 0, 0 );
    lights[1] = new THREE.DirectionalLight( 0x11E8BB, 1 );
    lights[1].position.set( 0.75, 1, 0.5 );
    lights[2] = new THREE.DirectionalLight( 0x8200C9, 1 );
    lights[2].position.set( -0.75, -1, 0.5 );
    // scene.add( lights[0] );
    scene.add( lights[1] );
    scene.add( lights[2] );
  }

  renderScene() {
    renderer.render( scene, camera );
  };


  initAnimation() {
    let animate = () => {
      requestAnimationFrame( animate );

      // Animating sphere 2
      pivotPoint.rotation.y += 0.01;
      for(var i = 0; i < 6; i++) {
        pivotPoint.rotation.x = i * 0.95
      }

      this.renderScene();  
    };

    // If Webgl available, initialize animation
    if ( WEBGL.isWebGLAvailable() ) {
      animate();
    } else {
      const warning = WEBGL.getWebGLErrorMessage();
      document.getElementById( 'lab' ).appendChild( warning );
    }
  }

  initObjects() {
    const geometry = new THREE.OctahedronBufferGeometry(10, 2);
    const material = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      wireframe: true
    });

    for (let i = 0; i < 6; i++) {
      let mesh = new THREE.Mesh(geometry, material);
      mesh.position.multiplyScalar(10 + Math.random() * 60);
      mesh.scale.x = mesh.scale.y = mesh.scale.z = 8;
      objects.push(mesh)
    }

    // Sphere Geometry 1
    const sunGeom = new THREE.OctahedronBufferGeometry(10);
    // Sphere Material 1
    const sunMat = new THREE.MeshLambertMaterial({
      color: 0xfccdd3,
      // wireframe: true
    });

    // Sphere Mesh 1
    diamondMesh = new THREE.Mesh(sunGeom, sunMat);
    diamondMesh.position.set(0, 0, 0);
    scene.add(diamondMesh);

    // Makes diamond pivot point
    pivotPoint = new THREE.Object3D();
    diamondMesh.add(pivotPoint);

    for(let _i=0; _i<6;_i++){
      let _coords = (new CoordinateTranslator()).fromSpherical(360,Math.PI*_i, 60*_i)
      objects[_i].position.set(_coords.x, _coords.y, _coords.z);
      pivotPoint.add(objects[_i]);
    }
    
    // Uses OBJLoader to map 3D object and render when loaded
    let loader = new OBJLoader();

    loader.load(
      'assets/objects/wolf.obj',
      function(object) {
        // console.log(object)
        scene.add(object)

        // Rotates 3D object on a loop
        TweenMax.to(object.rotation, 20, {
          ease: Linear.easeNone,
          y: "+=5",
          repeat: -1
        })
      },
      // called when loading is in progresses
      function ( xhr ) {
        console.log( ` 3D Object loaded: ${( xhr.loaded / xhr.total * 100 )}%` );
      },
      // called when loading has errors
      function ( error ) {
        console.log( error );
      }
    );
  };

  onScroll(event) {
    console.log(event)
  }

  render() {
    return (
      <DefaultLayout>       
        <div ref={mount => {this.mount = mount;}} id='lab' className='lab' />
      </DefaultLayout>
    )
  }
};

export default Lab;