import React from 'react'

import DefaultLayout from '../DefaultLayout';

const ContactPage = () => {
  return (
    <DefaultLayout>
      <div>Contact Page</div>
    </DefaultLayout>
  )
}

export default ContactPage