import React from 'react'

import DefaultLayout from '../DefaultLayout';

const ns = "about"

const AboutPage = () => {
  return (
    <DefaultLayout>
      <div className={ns}>
        <h1 className={`${ns}__heading`}>About</h1>
        <div className={`${ns}__info`}>
          <p className={`${ns}__info--paragraph`}>
            I'm a software engineer currently based in Orange County, CA. I'm a 25-year-old biologist turned developer who graduated from the University of Colorado Denver with a B.S. in Biology. I graduated from the University of Denver's coding program in 2018. I'm passionate about delivering clean, efficient, and concise code *and having fun along the way :). I love learning new tech stacks and constantly building upon my coding skills, as well as design skills. As long as I'm able to work in a creative environment I'm happy. I enjoy working with 3D graphics, animations, and interactive design.
          </p>
          <p className={`${ns}__info--paragraph`}>
            Currently, I'm loving working with Blender, Three.js, and C4D for 3D graphics and Figma for your everyday designs. Framework wise, I'm fond of React.js and Gatsby.js. I'm also passionate about mobile development and am currently using Flutter and React Native.
          </p>
          <p className={`${ns}__info--paragraph`}>
            In my free time, I love hiking, kayaking, and going to the beach with my pup Barry. I'm also a lover of music, and you will always catch me at a music festival.
          </p>
          <p className={`${ns}__info--paragraph`}>
            Want to get in touch? Just shoot me an <a href="mailto:aagiideegii@gmail.com" className={`${ns}__info--email`}>email</a>
          </p>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default AboutPage