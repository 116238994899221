import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { GlobalStyle } from './js/theme/globalStyle'
import './scss/main.scss'
import Home from './js/pages/Home'
import About from './js/pages/About'
import ProjectsV2 from './js/pages/ProjectsV2'
import ProjectsV3 from './js/pages/ProjectsV3'
import Contact from './js/pages/Contact'
import ProjectsV1 from './js/pages/ProjectsV1'
import PolygonWorld from './js/pages/PolygonWorld'
import Lab from './js/pages/Lab'

class App extends Component {

  render() {
    return (
      <div className="App">
        <GlobalStyle />
        <Router>
          <main>
            <Switch>
              <Route path='/' exact component={Home}></Route>
              <Route path='/about' component={About}></Route>
              <Route path='/work' component={ProjectsV3}></Route>
              <Route path='/work/:project' component={ProjectsV3}></Route>
              <Route path='/contact' component={Contact}></Route>
              <Route path='/workV1' component={ProjectsV1}></Route>
              <Route path='/workV2' component={ProjectsV2}></Route>
              <Route path='/polygon' component={PolygonWorld}></Route>
              <Route path='/lab' component={Lab}></Route>
              {/* <Route path='/lab' component={Lab}></Route> */}
            </Switch>
          </main>
        </Router>
      </div>
    )
  }
}

export default App;
