import React, { Component } from 'react'
import styled from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import Media from 'react-media'

import BgAnimationWrapper from '../BgAnimationWrapper'

const ImageSize = {
  sm: {
    height: "250px",
    width: "250px"
  },
  md: {
    height: "550px",
    width: "250px"
  }
}

const Image = styled.img`
  height: ${props => props.size.height};
  width: ${props => props.size.width};
`

const ImageWrapper = styled.div`
  position: absolute
  top: 0%;
  left: 40%;
  display: flex;

  @media screen and (max-width: 840px) {
    left: 10%;
  }

  .img-sm {
    position: relative;
    margin-right: -20px;
    margin-top: 70%;
    z-index: 2;
  }
`

const Title = styled.h1`
  text-transform: uppercase;
`

const Copy = styled.p`
  margin-left: 25%;

  @media screen and (max-width: 1140px) {
    margin-left: 0%;
  }
`

const CopyWrapper = styled.div`
  max-width: 400px;
  position: absolute;
  z-index: 10;
  color: #FFF;
  top: 40%;
  right: -40%;

  @media screen and (max-width: 1140px) {
    top: 120%;
    left: 10%;
    right: unset;
  }
`

class ProjectV2 extends Component {  
  render() {
    return (
      <BgAnimationWrapper bgColor={this.props.bgColor} path={this.props.path}>     
        <ImageWrapper>
          <Media query="(max-width: 1140px)">
          {matches =>
            matches ? (
              <Image size={ImageSize.sm} className="img-sm" src={this.props.img}></Image>
            ) : (
            <Parallax
              className="blob-parallax"
              offsetYMax={35}
              offsetYMin={-35}
              slowerScrollRate
            >
              <Image size={ImageSize.sm} className="img-sm" src={this.props.img}></Image>
            </Parallax>
            )
          }
          </Media>
          <Image size={ImageSize.md} src={this.props.img}></Image>
        </ImageWrapper>
  
        <CopyWrapper>
         <Media query="(max-width: 1140px)">
          {matches =>
            matches ? (
            <React.Fragment>
              <Title>{this.props.title}</Title>
              <Copy>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Copy>
            </React.Fragment>
            ) :
          (
          <Parallax
            className="blob-parallax"
            offsetYMax={35}
            offsetYMin={-35}
            slowerScrollRate
          > 
            <Title>{this.props.title}</Title>
            <Copy>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Copy>
          </Parallax>
          )}
          </Media>
        </CopyWrapper>
      </BgAnimationWrapper>
    )
  }
}

export default ProjectV2