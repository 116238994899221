import React, { Component } from 'react'
import styled from 'styled-components'
import Hammer from 'hammerjs'
import anime from 'animejs'
import imagesLoaded from 'imagesloaded'

import projectData from '../../../projects.json'

const ProjectContainer = styled.div.attrs({
  className: 'project__container loading',
})`

  height: 100vh;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  background-color: ${props => props.bgColor};
  // background-color: #FFF;
  position: relative;
  
  .project__image {
    // width: 100%;
    width: 60%;
  }
`
// const RightArrow = styled.img`
//   position: absolute;
//   width: 8%;
//   right: 16%;
//   top: 50%;
// `

// const LeftArrow = styled.img`
//   transform: rotate(180deg);
//   position: absolute;
//   width: 8%;
//   left: 16%;
//   top: 50%;
// `

const TitleContainer = styled.div`
  position: absolute;
  right: 7%;
  top: 20%;
  width: 700px;
`
  const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 400;
  color: #FFF;
  font-size: 90px;
  text-align: center;
`

const initAnimationLoop = (imgId) => {
  imgId = imgId || 0

  anime.remove('.path')
  anime({
    targets: '.path',
    d: [
      {
        value: projectData[imgId].path.secondary,
        duration: 1500
      },
      {
        value: projectData[imgId].path.primary,
        duration: 1500
      },
    ],
    loop: true,
    direction: 'alternate',
    easing: 'easeInOutQuad',
  })
}

class ProjectsV1 extends Component {
  state = {
    imgId: 0,
  }

  componentDidMount() {
    // const projectImage = document.querySelector('.project__image')
    const projectContainer = document.querySelector('.project__container')
    this.hammer = Hammer(projectContainer, {
      // threshold: 0,
      // velocity: 0.1
    })
    this.hammer.on('swipeleft', this.next);
    this.hammer.on('swiperight', this.prev);

    const init = () => {
      imagesLoaded(document.body, () => {
        this.setAnimationElement()
        projectContainer.classList.remove('loading');
      })
    }
    init()    
  }

  componentWillUnmount() {
    this.hammer.off('swipeleft', this.next)
    this.hammer.off('swiperight', this.prev)
  }

  setAnimationElement = (imgId) => {
    imgId = imgId || 0

    anime.remove(".project__image")
    anime({
      targets: '.project__image',
      duration: 1,
      easing: 'linear',
      // scaleX: projectData[imgId].scaleX,
      // scaleY: projectData[imgId].scaleY,
    })

    initAnimationLoop(imgId)
  }

  initAnimationOnChange = (imgId) => {
    anime.remove('.path')
    anime({
      targets: '.path',
      duration: 1000,
      easing: 'easeInOutQuad',
      elasticity: 400,
      d: projectData[imgId].path.primary,
      complete: function() {
        initAnimationLoop(imgId)
      }
    })

    anime.remove('.project__image')
    anime({
      targets: 'project__image',
      duration: 1000,
      easing: 'easeInOutQuad',
      scaleX: projectData[imgId].scaleX,
      scaleY: projectData[imgId].scaleY,
    })
  }

  next = () => {
    const { imgId } = this.state

    imgId < projectData.length - 1 ? 
    this.setState({ imgId: imgId + 1 })
    :
    this.setState({ imgId: 0 })

    this.initAnimationOnChange(this.state.imgId)
  }

  prev = () =>  {
    const { imgId } = this.state

    imgId > 0 ? 
    this.setState({ imgId: imgId - 1 })
    :
    this.setState({ imgId: projectData.length - 1 })

    this.initAnimationOnChange(this.state.imgId)
  }
  
  render() {
    const { imgId } = this.state

    return (
      <div className={`project`}>
          {/* <LeftArrow src='/assets/brush-arrow-right.png' onClick={this.prev}/> */}
          <ProjectContainer id={imgId} bgColor={projectData[imgId].bgColor}>
            <svg className="project__image" width="1000" height="600" viewBox="0 0 1400 800" fill='none' xmlns='http://www.w3.org/2000/svg'>
            {/* <svg className="project__image" width="1400" height="700" viewBox="0 0 1200 800" fill='none' xmlns='http://www.w3.org/2000/svg'> */}
              <defs>
                <pattern id={`img--${projectData[imgId].id}`} patternUnits="userSpaceOnUse" x="0%" y="0%" height="100%" width="100%">
                  <image x="0%" y="0%" width="100%" height="100%" xlinkHref={projectData[imgId].img}></image>
                </pattern>

                <filter id="filter--shadow" x="0" y="0" width="200%" height="200%">
                  <feOffset result="offOut" in="SourceGraphic" dx="15" dy="15" />
                  <feColorMatrix result="matrixOut" in="offOut" type="matrix"
                  values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0" />
                  <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="10" />
                  <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
              </defs>

              <path className='path' d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z"
              fill={`url(#img--${projectData[imgId].id})`} fillOpacity="0.8" filter="url(#filter--shadow)" />
            </svg>

            <TitleContainer> 
              <Title>
                {projectData[imgId].title} 
              </Title>
            </TitleContainer>
          </ProjectContainer>
          {/* <RightArrow src='/assets/brush-arrow-right.png' onClick={this.next}/> */}
      </div>
    )
  }
}

export default ProjectsV1