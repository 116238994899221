import React from 'react'

import ProjectV2 from '../../components/ProjectV2'
import projectData from '../../../projects.json'

const ProjectsPageV2 = () => {
  return (
    <div>
      {projectData.map(p => (
        <ProjectV2
          key={p.id}
          img={p.img}
          bgColor={p.bgColor}
          path={p.path}
          title={p.title}
        />
      ))}
    </div>
  )
}

export default ProjectsPageV2

