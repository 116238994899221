import React from 'react';

import Navigation from '../../components/Navigation';

const ns = 'default-layout';

const DefaultLayout = (props) => {
  return(
    <div className={ns}>
      <div className={`${ns}__navigation`}>
        <Navigation/>
      </div>
      <div className={`${ns}__content`}>
        {props.children}
      </div>
    </div>
  )
};

export default DefaultLayout;